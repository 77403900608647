import { html } from 'lit-element';
import { LitEntity } from '../../utility/lit-entity';
import './bin-smoothing-enabled';

class BinLatestLevel extends LitEntity {
  constructor() {
    super();
    this.latestLevelLoaded = false;
    this.useSmoothedValues = true;
  }

  static get properties() {
    return {
      latestLevelHref: { type: String, attribute: false },
      latestLevel: { type: Object, attribute: false },
      latestLevelLoaded: { type: Boolean, attribute: false },
      useSmoothedValues: { type: Boolean, attribute: false },
      latestLabel: { type: String, attribute: false },
    };
  }

  render() {
    return html`
      <lit-entity .href="${this.latestLevelHref}" @update="${e => this._latestLevelChanged(e.detail)}"></lit-entity>
      <bin-smoothing-enabled .href="${this.href}" @smoothingEnabled="${e => this._smoothingEnabledChanged(e)}"></bin-smoothing-enabled>
    `;
  }

  _entityChanged(entity) {
    super._entityChanged(entity);
    const latestLevelHref = this.getHref('https://api.binsentry.com/rel/bin-level-latest');

    if (latestLevelHref !== this.latestLevelHref) {
      this.latestLevelLoaded = false;
      this.latestLevelHref = latestLevelHref;
      this.latestLevel = undefined;
    }
  }

  update(changed) {
    super.update(changed);
    this._checkLevelUpdate(changed);
  }

  _checkLevelUpdate(changed) {
    // require all to be loaded
    if (!this.latestLevelLoaded || typeof this.useSmoothedValues === 'undefined' ) {
      return;
    }

    // only fire event if something changed
    if (!(changed.has('latestLevel') || changed.has('useSmoothedValues'))) {
      return;
    }

    let volume;
    if (this.latestLevel) {
      volume = this.useSmoothedValues
        ? this.latestLevel.properties.estimatedVolume ?? this.latestLevel.properties.volume
        : this.latestLevel.properties.volume;
    }

    this.dispatchEvent(new CustomEvent('levelUpdate', {
      detail: {
        latestLevel: this.latestLevel,
        volume,
        latestLabel: this.latestLabel,
      },
    }));
  }

  _latestLevelChanged({ entity }) {
    this.latestLevelLoaded = true;
    this.latestLevel = entity;
    this.latestLabel = entity.properties.label;
  }

  _smoothingEnabledChanged({ detail }) {
    const { smoothingEnabled } = detail;
    this.useSmoothedValues = smoothingEnabled;
  }
}

window.customElements.define('bin-latest-level', BinLatestLevel);
