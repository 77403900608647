import * as React from 'react';
import ReactDOM from 'react-dom';

import { RootUtility, useStoreValues } from './utility/BinSentry-ui-utility';
import Navigation from './navigation/navigation';
import { BnDynamicComponent } from './common/bn-dynamic-component';
import { publicPaths } from './utility/public-paths';
import { getRootElement } from './utility/get-root-element';
import { BnErrorBoundary } from './common/bn-error-boundary';

const config = JSON.parse(import.meta.env.VITE_PROJECT_AWS_SSM_CONFIG);
const litConfig = {
  href: config['api-root'],
  authConfig: JSON.stringify(config.auth),
};

const loadReactRoot = async () => (await import('./root-react-component.jsx')).default;
const loadLitRoot = async () => (await import('./legacy-lit/BinSentry-ui.jsx')).default;
const load3dToolsRoot = async () => (await import('./3d-advanced-view/BinSentry-ui-3d-advanced-view.jsx')).default;

const reactPaths = [
  'account',
  'inventory',
  'barns',
  'bins',
  'billing',
  'billing-management',
  'customers',
  'insights',
  'operations',
  'feed-budgets',
  'notifications',
  'feed-orders',
  'feeds',
  'order-scheduler',
  'orders',
  'reports',
  'work-order-locations',
  'internal',
  'print',
];

function PageContainer({ children }) {
  // The div container is important as we are in a display: grid context, so we want to encapsulate
  // all view components into a single node.
  // It is styled with static styles defined in index.html
  return <BnErrorBoundary locationTag='page-container'>
    <div className='page-container'>
      {children}
    </div>
  </BnErrorBoundary>;
}

function TopLevelRouter () {
  const { path } = useStoreValues(['path']);
  const firstPathSegment = (path || '').split('/')[1];

  if (firstPathSegment === 'print') {
    return <PageContainer>
      <BnDynamicComponent getComponent={loadReactRoot} componentProperties={config} />
    </PageContainer>;
  }

  if (firstPathSegment === '3d-image-tools') {
    return <PageContainer>
      <BnDynamicComponent getComponent={load3dToolsRoot} componentProperties={config} />
    </PageContainer>;
  }

  if (publicPaths.includes(firstPathSegment)) {
    return <PageContainer>
      <BnDynamicComponent getComponent={loadLitRoot} componentProperties={litConfig} />
    </PageContainer>;
  }

  if (reactPaths.includes(firstPathSegment)) {
    return <Navigation {...config} >
      <PageContainer>
        <BnDynamicComponent getComponent={loadReactRoot} componentProperties={config} />
      </PageContainer>
    </Navigation>;
  }

  return <Navigation {...config} >
    <PageContainer>
      <BnDynamicComponent getComponent={loadLitRoot} componentProperties={litConfig} />
    </PageContainer>
  </Navigation>;
}

ReactDOM.render(
  <React.StrictMode>
    <BnErrorBoundary locationTag='root'>
      <RootUtility {...config} />
      <TopLevelRouter />
    </BnErrorBoundary>
  </React.StrictMode>,
  getRootElement(),
);
